
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.brief {
    color: #686868;
}
.title2 {
    color: #00a8b1;
}
